@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@300&family=Playfair+Display&family=Poppins:wght@600&display=swap');

*,
::after,
::before {
    box-sizing: border-box;
    /* background-color: #FAF5F5; */

}

body {
    padding: 0;
    margin: 0;
    overflow-x: hidden !important;
    background-color: #FAF5F5 !important;
    height: 100% !important;

}



.bp-sidebar {
    display: flex;
    flex-direction: row;
}

.bp-leftSide {
    top: 0px;
    left: 0px;
    width: 100px;
    height: 979px;
    /* UI Properties */
    background: #A502A5 0% 0% no-repeat padding-box;
    opacity: 1;
}

.bp-rightSide {
    top: 0px;
    left: 100px;
    width: 100%;
    height: 979px;
    /* UI Properties */
    background: #FAF5F5 0% 0% no-repeat padding-box;
    opacity: 1;
}

.bp-logo {
    top: 31px;
    left: 149px;
    width: 37px;
    height: 40px;

}

.bp-container {
    margin-left: 49px;
    margin-right: 49px;
    margin-top: 31px;
    display: flex;
    justify-content: space-between;

}

.bp-logoContainer {
    top: 31px;
    left: 149px;
    width: 206px;
    height: 41px;
    /* UI Properties */
    opacity: 1;
    /* background-color: rebeccapurple; */
}

.bp-sidebarFont {
    top: 33px;
    left: 193px;
    width: 162px;
    height: 22px;
    text-align: left;
    font: normal normal normal 20px/28px Georgia;
    letter-spacing: 0px;
    color: #000000;
    opacity: 1;
}

.bp-sidebarFont2 {
    /* Layout Properties */
    top: 55px;
    left: 193px;
    width: 94px;
    height: 17px;
    /* UI Properties */
    text-align: left;
    font: normal normal 600 12px/28px Open Sans;
    letter-spacing: 0.48px;
    color: #555555;
    text-transform: uppercase;
    opacity: 1;
}

.bp-appointment-container {
    top: 112px;
    left: 141px;
    /* width: 1177px; */
    height: 277px;
    margin-left: 41px;
    margin-right: 41px;
    margin-top: 40px;
    /* UI Properties */
    background: #FFFFFF 0% 0% no-repeat padding-box;
    border: 1px solid #7070702B;
    border-radius: 6px;
    opacity: 1;
    /* background-color: #A502A5; */

}

.bp-table-container {
    margin-top: 27px;
}

/* Table */
/* table {
    border-collapse: collapse;
}

th,
td {
    border-block: 1px solid rgb(216, 216, 216) !important;
    height: 46px;
}

table.d {
    table-layout: fixed;
    width: 100%;
} */


.bp-appointment-font {

    padding-left: 48px !important;
    margin-top: 33px !important;
    font: normal normal bold 24px/28px Playfair Display;
    letter-spacing: 0px;
    color: #333333;
    opacity: 1;
}

.bp-appointment-font2 {

    padding-left: 54px !important;
    margin-top: 33px !important;
    font: normal normal bold 24px/28px Playfair Display;
    letter-spacing: 0px;
    color: #333333;
    opacity: 1;
}


.bp-td-date {
    font: normal normal normal 14px/28px Open Sans;
    letter-spacing: 0px;
    padding-left: 54px;
    color: #333333;
    opacity: 1;
}

.bp-td-appointment {
    font: normal normal normal 14px/28px Open Sans;
    letter-spacing: 0px;
    color: #333333;
    opacity: 1;
    padding-left: 136px;
}

.bp-td-practitioner {
    font: normal normal normal 14px/28px Open Sans;
    letter-spacing: 0px;
    color: #333333;
    opacity: 1;
    padding-left: 134.5px;
}

.bp-td-payment {
    font: normal normal normal 14px/28px Open Sans;
    letter-spacing: 0px;
    color: #333333;
    opacity: 1;
    padding-left: 167.5px;
}

.bp-td-receipt {
    font: normal normal normal 14px/28px Open Sans;
    letter-spacing: 0px;
    color: #333333;
    opacity: 1;
    /* padding-left: 178.5px; */
    padding-left: 154.5px;
    text-decoration: underline;
}

.bp-table-color {
    background-color: #e5e5e5;
    /* background: #CCCCCC 0% 0% no-repeat padding-box; */
    /* opacity: 0.5; */
    /* height: 4px; */

}

.bp-tr-2 {
    height: 68px;
}

.bp-name {
    /* top: 43px;
    left: 1219px;
    width: 75px;
    height: 25px; */
    /* text-align: left; */
    /* padding-left: 6px; */

    font: normal normal normal 14px/28px Open Sans !important;
    letter-spacing: 0px;
    color: #333333 !important;
    opacity: 1;
    background-color: #FAF5F5 !important;
    border-color: #FAF5F5 !important;
}



.bp-name:focus,
.bp-name:hover,
.bp-name:active {
    box-shadow: none !important;
    background-color: #FAF5F5 !important;
    color: black !important;

}

.dropdown-item.active,
.dropdown-item:active {
    color: var(--bs-dropdown-link-active-color);
    text-decoration: none;
    background-color: white !important;
    color: black !important;

}

.bp-avatar {

    width: 28px;
    height: 28px;
}


.bp-profile-container {
    display: flex;
    align-content: center;
    align-items: center;
}

.dropdown-toggle::after {
    margin-left: 1em !important;

}

.bp-payment {
    top: 277px;
    left: 942px;
    width: 92px;
    height: 25px;
    text-align: center;
    font: normal normal 600 11px/28px Open Sans;
    letter-spacing: 0.22px;
    color: #E23A3A;
    background-color: #FAF5F5;
    opacity: 1;
}

.bp-payemnt-completed {

    top: 277px;
    left: 942px;
    width: 92px;
    height: 25px;
    text-align: center;
    font: normal normal 600 11px/28px Open Sans;
    letter-spacing: 0.22px;
    color: #2CAC36;
    background-color: #FAF5F5;
    opacity: 1;
}

.bp-btn-payment {

    /* width: 75px; */
    width: 125px;
    height: 33px;
    border: 1px solid #A501A5;
    border-radius: 4px;
    opacity: 1;
    display: flex;
    /* text-align: center; */
    font: normal normal 600 11px/47px Open Sans;
    letter-spacing: 0.22px;
    color: #A502A5;
    text-transform: uppercase;
    align-items: center;
    justify-content: space-around;

}

.bp-home-icon {
    margin-top: 112px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.bp-payment-icon {
    margin-top: 60px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.bp-account-icon {
    margin-top: 65px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.form-check-input:checked {
    background-color: #A501A5 !important;
    border-color: #A501A5 !important;

}

.form-check-input:focus {
    border-color: #A501A5 !important;
    box-shadow: none !important;
}

.bp-icon-home {
    width: 25px;
    height: 25px;
    color: white;
    margin-left: 39px;
    margin-right: 36px;
}

.bp-icon-font {
    font: normal normal 600 14px/19px Open Sans;
    letter-spacing: 0px;
    color: #FFFFFF;
    opacity: 1;
    cursor: pointer;
}

.bp-modal {
    margin-top: 15%;

}

.modal-content {

    background: #FFFFFF 0% 0% no-repeat padding-box !important;
    border: 1px solid #7070702B !important;
    border-radius: 6px;
    opacity: 1;
}

.modal-backdrop {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1050;
    width: 104vw !important;
    height: 100vh;
    background-color: #000;
}


.bp-modal-heading {
    /* Layout Properties */
    top: 405px;
    left: 504px;
    width: 390px;
    height: 65px;
    /* UI Properties */
    text-align: center;
    font: normal normal bold 25px/32px Playfair Display;
    letter-spacing: 0px;
    color: #333333;
    opacity: 1;
}

.bp-modal-container {
    padding-top: 83px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

}

.bp-sub-heading {
    padding-top: 23px;
    left: 10px;
    width: 426px;
    height: 22px;
    /* UI Properties */
    text-align: center;
    font: normal normal bold 16px/22px Open Sans;
    letter-spacing: 0px;
    color: #333333;
}

.bp-sub-heading2 {
    padding-top: 45px;
    left: 10px;
    width: 464px;
    height: 22px;
    /* UI Properties */
    text-align: center;
    font: normal normal normal 16px/22px Open Sans;
    letter-spacing: 0px;
    color: #333333;
}

.bp-btn-setupPayment {
    /* top: 608px; */
    left: 573px;
    width: 304px;
    height: 46px;
    margin-top: 70px;

    display: flex;
    align-items: center;
    justify-content: center;
    align-content: center;

    /* UI Properties */
    font: normal normal 900 17px/47px Playfair Display;
    letter-spacing: 0.17px;
    background: #A501A5 0% 0% no-repeat padding-box;
    border: 2px solid #A501A5;
    border-radius: 6px;
    opacity: 1;
    color: #FFFFFF;

}

.bp-account-container {
    top: 112px;
    left: 141px;
    /* width: 1177px; */
    height: 277px;
    margin-left: 41px;
    margin-right: 41px;
    margin-top: 40px;
    /* UI Properties */
    background: #FFFFFF 0% 0% no-repeat padding-box;
    border: 1px solid #7070702B;
    border-radius: 6px;
    opacity: 1;
}

.bp-account-card {
    top: 33px;
    left: 54px;
    width: 404px !important;
    height: 134px !important;
    border: 1px solid #E7E7E7 !important;
    border-radius: 8px !important;
    opacity: 1;

}

.bp-account-card2 {
    top: 41px;
    left: 54px;
    width: 130px;
    height: 15vh !important;
    border: 1px solid #E7E7E7 !important;
    border-radius: 8px !important;
    opacity: 1;

}

.bp-dot-icon {

    display: flex;
    justify-content: flex-end;
    padding-right: 24px !important;
    /* padding-top: 10px; */
    color: #C1C1C1 !important;
}

.bp-default-payment {
    background-color: #cff2e0 !important;
}

.bp-default-font {

    width: 182px;
    height: 17px;
    padding-left: 4.65px;
    /* UI Properties */
    text-align: left;
    font: normal normal normal 13px/28px Open Sans;
    letter-spacing: 0px;
    color: #098333;
    opacity: 1;
    cursor: pointer;
}

.bp-expired-font {

    width: 182px;
    height: 17px;
    padding-left: 4.65px;
    /* UI Properties */
    text-align: left;
    font: normal normal normal 13px/28px Open Sans;
    letter-spacing: 0px;
    color: #333333;
    opacity: 1;
    cursor: pointer;
}

.bp-card-flex {
    display: flex;
    align-content: center;
    align-items: center;
    justify-content: space-between;
    padding-left: 24px !important;
}

.bp-card-number {
    font: normal normal normal 14px/28px Open Sans;
    letter-spacing: 0px;
    color: #333333;
    opacity: 1;
}

.bp-card-year {
    font: normal normal normal 14px/28px Open Sans;
    letter-spacing: 0px;
    color: #333333;
    opacity: 1;
}

.bp-card-container {
    display: flex;
}

.bp-card-position {
    margin-left: 33px
}

.card-body {
    padding: 0 !important;

}

.bp-icons-plus {
    display: flex;
    padding-top: 20px;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font: normal normal 600 11px/15px Open Sans;
    letter-spacing: 0.22px;
    color: #817D7D;
    text-transform: uppercase;
    opacity: 1;

}

.bp-payment-name {
    font: normal normal 600 14px/28px Open Sans;
    letter-spacing: 0px;
    color: #333333;
    opacity: 1;
    cursor: pointer;
    /* margin-left: 84px; */
}

.bp-account-email {
    /* margin-left: 84px; */
}

.bp-account-password {
    /* margin-left: 168px; */
}

.bp-email-wrapper {

    padding-left: 60px;
    margin-top: 20px;

}

.bp-common-email {
    /* margin-left: 57px; */
    cursor: pointer;
}

.bp-common-password {
    /* margin-left: 57px; */
    padding-bottom: 15px;
    text-decoration: underline;
    cursor: pointer;
}

.bp-common-names {
    margin-top: 15px;
    font: normal normal normal 16px/28px Open Sans;
    letter-spacing: 0px;
    color: #333333;
    opacity: 1;
}

.bp-language-btn {
    margin-top: 15px;
    font: normal normal normal 16px/28px Open Sans;
    letter-spacing: 0px;
    color: #333333;
    opacity: 1;
}

.bp-eng-btn {
    margin-left: 20px;
}

.bp-lng-btn {

    width: 105px;
    background: #A501A5 0% 0% no-repeat padding-box;
    border: 2px solid #A501A5;
    border-radius: 6px;
    color: white;
    height: 40px;
}

/* .bp-submit-btn{
    width: 105px;
    background: #A501A5 0% 0% no-repeat padding-box;
    border: 2px solid #A501A5;
    border-radius: 6px;
    color:white;
} */
/* Login.tsx */
.bp-login-wrapper {
    display: flex;
    justify-content: space-around;
    padding-top: 212px;
}

.bp-signup-wrapper {
    display: flex;
    justify-content: space-around;
    padding-top: 23px;
}

.bp-login-wrapper2 {
    display: flex;
    justify-content: space-around;
    padding-top: 55px;
}

.bp-login-card {
    width: 630px !important;
    /* height: 569px !important; */
    height: 583px !important;
    background: #FFFFFF 0% 0% no-repeat padding-box !important;
    border: 1px solid #7070702B !important;
    border-radius: 6px !important;
    opacity: 1 !important;
}

.bp-payment-card {
    width: 630px !important;
    /* height: 569px !important; */
    height: 960px !important;
    background: #FFFFFF 0% 0% no-repeat padding-box !important;
    border: 1px solid #7070702B !important;
    border-radius: 6px !important;
    opacity: 1 !important;
}

.bp-signup-card {
    width: 630px !important;
    height: 812px !important;
    background: #FFFFFF 0% 0% no-repeat padding-box !important;
    border: 1px solid #7070702B !important;
    border-radius: 6px !important;
    opacity: 1 !important;
}

.bp-login-card2 {
    width: 630px;
    height: 749px !important;

    background: #FFFFFF 0% 0% no-repeat padding-box !important;
    border: 1px solid #7070702B !important;
    border-radius: 6px !important;
    opacity: 1 !important;
}


.bp-login-logo {
    width: 137px;
    height: 67px;
}

.bp-lg-wrapper {
    /* padding-top: 53px; */
}

/* 11!!!!!!!!!!!!111111111111111111111111 */
.bp-form-container {
    margin-left: 125px !important;
    margin-right: 132px !important;
}

.bp-login-input {
    /* width: 366px !important; */
    width: 346px !important;
    height: 47px !important;
    background: #FFFFFF 0% 0% no-repeat padding-box !important;
    box-shadow: 0px 3px 6px #0000000B !important;
    border: 1px solid #DDDDDD !important;
    opacity: 1 !important;
}

/* 111111111111111111111111111 */

.bp-Form-wrapper {
    display: flex;
    justify-content: space-around;
    align-items: center;
    align-content: center;
}

.bp-login-label {

    margin-bottom: 0 !important;
    font: normal normal 600 14px/28px Open Sans;
    letter-spacing: 0px;
    color: #333333;
    opacity: 1;
}

.bp-login-btn {
    /* width: 366px; */
    width: 346px;
    height: 46px;
    margin-top: 31px;

    display: flex;
    align-items: center;
    justify-content: center;

    /* UI Properties */
    background: #A501A5 0% 0% no-repeat padding-box;
    border: 2px solid #A501A5;
    border-radius: 6px;
    opacity: 1;

    font: normal normal 900 17px/47px Playfair Display;
    letter-spacing: 0.17px;
    color: #FFFFFF;
    margin-bottom: 15px;

}

.bp-google-login-btn {
    width: 346px;
    height: 46px;
    /* margin-top: 31px; */

    display: flex;
    align-items: center;
    justify-content: center;

    /* UI Properties */
    background: #A501A5 0% 0% no-repeat padding-box;
    border: 2px solid #A501A5;
    border-radius: 6px;
    opacity: 1;

    font: normal normal 900 17px/47px Playfair Display;
    letter-spacing: 0.17px;
    /* color: black; */
    color: #FFFFFF;
    margin-bottom: 15px;
}


.bp-microVerification-btn {
    /* width: 366px; */
    width: 185px;
    height: 46px;
    margin-top: 31px;

    display: flex;
    align-items: center;
    justify-content: center;

    /* UI Properties */
    background: #A501A5 0% 0% no-repeat padding-box;
    border: 2px solid #A501A5;
    border-radius: 6px;
    opacity: 1;

    font: normal normal 900 17px/47px Playfair Display;
    letter-spacing: 0.17px;
    color: #FFFFFF;
    margin-bottom: 15px;

}

.bp-continue-btn {
    width: 366px;
    height: 46px;
    margin-top: 10px;

    display: flex;
    align-items: center;
    justify-content: center;

    /* UI Properties */
    background: #A501A5 0% 0% no-repeat padding-box;
    border: 2px solid #A501A5;
    border-radius: 6px;
    opacity: 1;

    font: normal normal 900 17px/47px Playfair Display;
    letter-spacing: 0.17px;
    color: #FFFFFF;

}

.bp-email {
    margin-top: 20px !important;
}

.bp-login-txt {
    margin-top: 16px;
    text-decoration: underline;
    font: normal normal normal 14px/28px Open Sans;
    letter-spacing: 0px;
    color: #333333;
    opacity: 0.53;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    align-content: center;
    cursor: pointer;
}

.bp-login-title {

    margin-top: 37px;
    display: flex;
    justify-content: center;
    align-items: center;
    align-content: center;


    font: normal normal 600 17px/28px Open Sans;
    letter-spacing: 0px;
    color: #A3A3A3;
    text-transform: uppercase;
    opacity: 1;
    /* 
    border-bottom: 1px solid #000;
    line-height: 0.1em;
    margin: 10px 0 20px; */
}

/* h2 {
    width: 100%; 
    text-align: center; 
    border-bottom: 1px solid #000; 
    line-height: 0.1em;
    margin: 10px 0 20px; 
 } 
 
 h2 span { 
     background:#fff; 
     padding:0 10px; 
 } */

.bp-lg-title {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    align-content: center;
    margin-top: 20px;
}

.bp-title-1 {
    font: normal normal bold 25px/28px Playfair Display;
    letter-spacing: 0px;
    color: #333333;
    opacity: 1;
}

.bp-title-2 {
    margin-top: 17px;
    font: normal normal normal 16px/28px Open Sans;
    letter-spacing: 0px;
    color: #333333;
    opacity: 1;
}

.bp-title-3 {
    /* margin-top: 9px; */
    font: normal normal normal 16px/28px Open Sans;
    letter-spacing: 0px;
    color: #333333;
    opacity: 1;
}

.bp-title-checkmail {
    display: flex;
}

.bp-send-link {


    text-decoration: underline;
    font: normal normal normal 14px/28px Open Sans;
    letter-spacing: 0px;
    color: #A502A5;
    cursor: pointer;
    /* 
    display: flex;
    align-items: center;
    justify-content: center;
    align-content: center */
}

.bp-receive-msg {
    font: normal normal normal 14px/28px Open Sans;
    letter-spacing: 0px;
    color: #333333;
}

.bp-email-msg-wrap {

    margin-top: 16px;
    display: flex;
    justify-content: center;
    align-items: center;
    align-content: center;

}

.bp-checkbox {
    width: 20px;
    height: 20px;
    /* UI Properties */
    background: #DDDDDD72 0% 0% no-repeat padding-box;
    border: 1px solid #DDDDDD;
    border-radius: 2px;
    opacity: 1;
}

/* .form-check-input[type=checkbox] {
    width: 20px ;
    height: 20px;
    background: #DDDDDD72 0% 0% no-repeat padding-box;
    border: 1px solid #DDDDDD;
    border-radius: 2px;
    opacity: 1;
} */

.form-check-input[type=checkbox] {
    width: 20px;
    height: 20px;
    background: #DDDDDD72 0% 0% no-repeat padding-box;
    border: 1px solid #DDDDDD;
    border-radius: 2px;
    opacity: 1;
    margin-top: 0 !important;
}

.bp-privacy-plcy {
    font: normal normal normal 14px/24px Open Sans;
    letter-spacing: 0px;
    color: #333333;
    opacity: 1;
    padding-left: 16px
}

.bp-privacy-plcy2 {
    font: normal normal normal 14px/24px Open Sans;
    letter-spacing: 0px;
    color: #333333;
    opacity: 1;
    text-decoration: underline;
    padding-left: 4px;

}

.bp-sub-titile {
    display: flex;
    flex-direction: row;
    /* width: 400px; */
    width: 365px;
}

.bp-sub-titile:before,
.bp-sub-titile:after {
    content: "";
    flex: 1 1;
    border-bottom: 1px solid #A3A3A3;
    margin-right: 15px !important;
    margin-left: 15px !important;
    margin: auto;
}


/* DELETE START */
.bp-client-portal {
    margin-top: 20px;

    width: 630px !important;
    height: 848px !important;
    /* UI Properties */
    background: #FFFFFF 0% 0% no-repeat padding-box;
    border: 1px solid #7070702B;
    border-radius: 6px;
    opacity: 1;
}

.bp-client-container {
    display: flex;
    display: flex;
    justify-content: center;
    align-content: center;
    align-items: center;
}

.bp-about-client {
    /* margin-top: 72px; */
    width: 362px !important;
    height: 65px;
    font: normal normal bold 25px/32px Playfair Display;
    letter-spacing: 0px;
    color: #333333;
    opacity: 1;
    text-align: center;

}

.bp-title {
    width: 104px;
    height: 30px;
    /* UI Properties */
    text-align: left;
    font: normal normal bold 22px/22px Playfair Display;
    letter-spacing: 0px;
    color: #333333;
}

.bp-purpose-details {
    margin-top: 15px !;
    width: 559px;
    height: 22px;
    font: normal normal normal 16px/22px Open Sans;
    letter-spacing: 0px;
    color: #333333;
}

.bp-deatils-wrapper {
    margin-top: 40px !important;
}

.bp-text-align {

    margin-left: 5px !important;

}

.bp-purpose-btn {

    display: flex;
    align-items: center;
    justify-content: center;
    width: 103px;
    height: 46px;
    margin-top: 20px;
    /* UI Properties */
    background: #A501A5 0% 0% no-repeat padding-box;
    border: 2px solid #A501A5;
    border-radius: 6px;
    opacity: 1;
    font: normal normal 900 17px/47px Playfair Display;
    letter-spacing: 0.17px;
    color: #FFFFFF;
}

/* DELETE END */

/* .App-header {
    background-color: #282c34;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
  } */

.modal-header {

    border-bottom: none !important;

}

.bp-purpose-deatils-wrapper {
    margin-top: 20px;
}

/* Account New style */
.bp-account-row {
    margin-left: 54px !important;
}

.bp-hr {
    /* margin: -1rem 0px; */
    color: inherit;
    border: 0;
    border-top: 1px solid;
    /* opacity: .25; */
    margin-top: 0px !important;
    background-color: #cff2e0 !important;
    height: 3.1vh;
    min-width: 100px !important;
}

.card-footer {
    padding: 0 !important;
    color: none !important;
    background-color: none !important;

}

.bp-tick-icon {
    padding-left: 35px !important;
}

.bp-account-card-container {
    display: flex;

}

.bp-plus-icon {
    width: 35px;
    height: 35px;
    opacity: 1;
    color: gray;
}

.bp-addPayment {
    padding-top: 13px;
    cursor: pointer;
}

.bp-dot-icon2 {
    width: 2rem !important;
    height: 2rem !important;
    color: #C1C1C1 !important;
}

.bp-dot-icon2:hover,
.bp-dot-icon2:active,
.bp-dot-icon2:focus {
    color: #A501A5;
}

.bp-card {
    margin-left: 33px;
}

.bp-payment-modal {
    width: 207px !important;
    height: 200px !important;
    margin-left: 21.4% !important;
    margin-top: 12.5% !important;
}

.bp-payment-modal2 {
    width: 207px !important;
    height: 200px !important;
    margin-left: 47.4% !important;
    margin-top: 12.5% !important;
}

.bp-payment-options {
    display: flex;
    flex-direction: column !important;
}

.bp-options {
    font: normal normal normal 14px/28px Open Sans;
    letter-spacing: 0px;
    color: #333333;
    opacity: 1;
    cursor: pointer;
}

.bp-options:hover,
.bp-options:active,
.bp-options:focus {
    color: #B635B6 !important;
    background-color: white !important;
}

.bp-account-name {
    padding-left: 60px;
    margin-top: 20px;
}

.bp-body {
    background-color: #FAF5F5;
    height: 977px;
}

.bp-dot-btn {
    background-color: white !important;
    border-color: white !important;
    width: 45px;
    height: 32px;
    display: flex !important;
    justify-content: flex-end !important;
    align-items: center;

}

.bp-dot-btn:hover,
.bp-dot-btn:active,
.bp-dot-btn:focus {
    box-shadow: none !important;
}

.dropdown-toggle::after {

    content: none !important;

}

.bp-payment-menu {
    transform: translate3d(215px, 33.6364px, 0px) !important;
}

.bp-card-img {
    width: 70px;
    height: 70px;
}

.bp-padcard-img {
    width: 35px;
    height: 35px;
}






/* SAMPLE */
.bp-menu-bar {
    width: 134px !important;
    height: 100vh !important;
    background: #A502A5 0% 0% no-repeat padding-box;
    opacity: 1;
}

.side-bar-icon {
    cursor: pointer;
    width: 25px;
    height: 25px;
    color: white;

}

.bp-blake-logo {

    width: 206px;
    height: 41px;
    opacity: 1;
}

.bp-blake-logo-row {
    margin-top: 31px !important;
    /* margin-left: 34px !important; */
    margin-left: 0 !important;
}

.bp-right-sidebar {
    background-color: #FAF5F5 !important;
    width: 100%;
    padding-left: 49px !important;
}

.bp-sidebar-container {

    /* width: 1177px !important; */
    width: 95% !important;
    height: 277px;
    margin-left: 15px;
    /* margin-right: 41px; */
    margin-top: 40px !important;
    /* UI Properties */
    background: #FFFFFF 0% 0% no-repeat padding-box;
    border: 1px solid #7070702B;
    border-radius: 6px;
    opacity: 1;
}

.bp-icon-position {
    margin-top: 47px !important;
}

.bp-avatar-wrapper {

    margin-left: 1100px;

}

/* SAMPLE END */


.bp-payment-icon {
    width: 70px;
    height: 70px;
}

.bp-cognito-login {
    margin-top: 10%;
}

.bp-confirmPassword {
    width: 100%;
    margin-top: 0.25rem;
    font-size: .875em;
    color: #dc3545;

}


.bp-account-table,
.bp-account-td,
.bp-account-th {
    text-align: left !important;
}

.bp-account-table {
    border-collapse: collapse !important;
    width: 100% !important;
    margin-top: 33px !important;
    margin-left: 41px !important;
}

.bp-account-th,
.bp-account-td {
    padding: 15px !important;
}

/* .bp-account-th {
    width: 90px !important;
    height: 25px !important;
} */
.bp-account-name {
    width: 150px !important;
    height: 25px !important;
}

.bp-account-mail {
    width: 240px !important;
}

.bp-payment-btn {
    background-color: #A501A5;
    color: white;
    border: none !important;
    width: 100px;
    height: 35px;
    border-radius: 5px;
    font: normal normal 600 14px/28px Open Sans;
    letter-spacing: 0px;


}

.bp-custom-select {
    width: 344px !important;
    height: 47px !important;
    background: #FFFFFF 0% 0% no-repeat padding-box !important;
    box-shadow: 0px 3px 6px #0000000b !important;
    border: 1px solid #DDDDDD !important;
    border-radius: 5px !important;
    opacity: 1 !important;
}

.bp-my-custom-select.bp-custom-select:active,
.bp-my-custom-select.bp-custom-select:hover,
.bp-my-custom-select.bp-custom-select:focus {
    background: #FFFFFF 0% 0% no-repeat padding-box !important;
    box-shadow: 0px 3px 6px #0000000b !important;
    /* border: 1px solid #DDDDDD !important; */
    border-color: #DDDDDD !important;
    outline: 0px !important;
}


.bp-select-language {
    width: 210px !important;
    height: 40px !important;
    font: normal normal normal 16px/28px Open Sans;
    background: #FFFFFF 0% 0% no-repeat padding-box !important;
    box-shadow: 0px 3px 6px #0000000b !important;
    border: 1px solid #DDDDDD !important;
    border-radius: 5px !important;
    opacity: 1 !important;
}

.bp-select-language:active,
.bp-select-language:hover,
.bp-select-language:focus {
    background: #FFFFFF 0% 0% no-repeat padding-box !important;
    box-shadow: 0px 3px 6px #0000000b !important;
    border: 1px solid #DDDDDD !important;
    border-color: none !important;
    outline: 0px !important;
}

.amplify-tabs-item[data-state=active] {
    color: #A501A5 !important;
    border-color: #A501A5 !important;
    transition-property: none;
}

.amplify-tabs-item {

    color: black !important;

}

.amplify-button--primary {
    background-color: #A501A5 !important;
    border-color: #A501A5 !important;
}

.amplify-button--link {
    border-width: var(--amplify-components-button-link-border-width);
    background-color: none !important;
    color: #A501A5 !important;
}

.amplify-button--link:active,
.amplify-button--link:focus,
.amplify-button--link:hover {
    background-color: #f5c6f5 !important;
    border-color: #f5c6f5 !important;
}

.amplify-button--loading,
.amplify-button--loading:active,
.amplify-button--loading:focus,
.amplify-button--loading:hover {
    color: white !important;
}

.amplify-loader circle:last-of-type {
    stroke: #A501A5 !important;
}

.amplify-input {
    background: #FFFFFF 0% 0% no-repeat padding-box !important;
    box-shadow: 0px 3px 6px #0000000b !important;
    border: 1px solid #DDDDDD !important;
    opacity: 1 !important;
}

.amplify-field-group__outer-end .amplify-field-group__control:not(:focus) {
    border-inline-start-color: transparent;
    background: #FFFFFF 0% 0% no-repeat padding-box !important;
    box-shadow: 0px 3px 6px #0000000b !important;
    border: 1px solid #DDDDDD !important;
    opacity: 1 !important;
}

.bp-progress-bar {
    width: 76.5rem !important;
    height: 31px !important;
    margin-top: 5rem;
}

.spinner-border {
    --bs-spinner-width: 3rem !important;
    --bs-spinner-height: 3rem !important;
    color: #A501A5 !important;
    margin-top: 71px !important;
    margin-left: 90px !important;

}

.bp-spinner {
    margin-left: 4% !important;
    margin-top: 6% !important;

}

.bp-message-btn {
    display: flex;
    justify-content: flex-end;
}

.bp-yes-btn {
    background-color: #A501A5;
    color: white;
    border: none !important;
    width: 100px;
    height: 35px;
    border-radius: 5px;
    font: normal normal 600 14px/28px Open Sans;
    letter-spacing: 0px;

}

.bp-no-btn {
    background-color: #A501A5;
    color: white;
    border: none !important;
    width: 100px;
    height: 35px;
    border-radius: 5px;
    font: normal normal 600 14px/28px Open Sans;
    letter-spacing: 0px;

}

.bp-modal-footer {
    border-top: none !important;
    display: flex !important;
    justify-content: center !important;
    margin-bottom: 20px !important;
}





.bp-checkbox-body {
    margin-left: 90% !important;
    margin-top: 3% !important;
    position: absolute;

}

.bp-checkbox {
    transform: scale(1.5);
    /* width: 7rem;
    height: 2.5rem; */
    width: 4rem;
    height: 1.5rem;
    border-radius: 20px;
    border: 2.5px solid #A501A5;
    background: #A501A5;
    color: white;
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
    box-shadow: 0px 0.5px 1px 0px rgba(50, 50, 50, 0.72);
}

.bp-checkbox--on {
    flex-direction: row;
    background: #A501A5;
    border: 2.5px solid #A501A5;
    color: white;
}

.bp-checkbox--on .bp-checkbox__ball {
    box-shadow: #A501A5;
}

.bp-checkbox__ball {
    background: whitesmoke;
    box-shadow: #A501A5;
    width: 20px;
    height: 100%;
    border-radius: 50%;
}

.bp-checkbox__text {
    font: normal normal 600 12px/28px Open Sans;
    margin-left: 7px;
    margin-right: 7px;
}




/* LOGIN  START*/
.bp-Auth-form-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
}

.bp-Auth-form {
    width: 630px;
    padding-top: 30px;
    padding-bottom: 20px;

    /* height: 583px !important; */
    background: #FFFFFF 0% 0% no-repeat padding-box !important;
    border: 1px solid #7070702B !important;
    border-radius: 6px !important;
    opacity: 1 !important;
}

.bp-Auth-form-signup {
    width: 630px;
    padding-top: 30px;
    padding-bottom: 20px;

    /* height: 583px !important; */
    background: #FFFFFF 0% 0% no-repeat padding-box !important;
    border: 1px solid #7070702B !important;
    border-radius: 6px !important;
    opacity: 1 !important;
    margin-top: 100px !important;
}

.bp-Auth-form-content {
    padding-left: 12%;
    padding-right: 12%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.bp-Auth-form-title {
    text-align: center;
    margin-bottom: 1em;
    font-size: 24px;
    color: rgb(34, 34, 34);
    font-weight: 800;
}

.bp-label {
    font-size: 14px;
    font-weight: 600;
    color: rgb(34, 34, 34);
}

.bp-ec-sbmt-btn {
    background-color: #6163FF !important;
    color: #ECEDED !important;
}

.bp-ec-sbmt-btn:hover {
    color: #25282c !important;
}

.bp-ec-forgot-password {
    font-size: 13px !important;
}


/* PAYMENT PAGE START*/
.bp-card-body {
    background: #FFFFFF 0% 0% no-repeat padding-box;
    border: 1px solid #7070702B;
    border-radius: 6px;
    opacity: 1;
    min-height: 24vh !important;
    margin-top: 40px !important;

    /* height: 277px; */
}

.bp-payment-method-card {
    margin-top: 40px;
    min-width: 330px !important;
    max-width: 330px !important;
    min-height: 140px !important;
    /* margin-right:90px !important */
}

.bp-default-btn {
    width: 100% !important;
    background-color: #cff2e0 !important;
    border: 1px solid #cff2e0 !important;
    border-radius: 0px 0px 6px 6px;
}

.bp-padDefault-btn {
    width: 100% !important;
    background-color: #cff2e0 !important;
    border: 1px solid #cff2e0 !important;
    border-radius: 0px 0px 6px 6px;
    bottom: 0 !important;
    position: absolute;
}

.bp-expired-btn {
    width: 100% !important;
    background-color: #b8b9b9 !important;
    border: 1px solid #b8b9b9 !important;
    border-radius: 0px 0px 6px 6px;
}

.bp-card-details {
    display: flex !important;
    align-items: center !important;
}

.bp-card-image {
    padding-left: 25px !important;
    width: 50px !important;
}

/* PAYMENT PAGE END */




/* HEADER PAGE START*/
.bp-avatar-icon {
    display: flex !important;
    align-items: center !important;
}

/* HEADER PAGE END */


/* HOME BODY  START*/
.bp-home-body {
    /* background-color: red; */
    width: 100vw;
    margin-left: 1% !important;
    display: block !important;

}

.bp-home-container {
    width: 100vw !important;
}

/* HOMEBODY END */


/* SIDEBAR START */
.bp-payment-body {
    margin-left: 40px !important;
}

/* SIDEBAR END */



/* oct27 2022 */
.bp-pymnt-card {
    width: 404px !important;
    height: 134px !important;
}

.bp-payment-add-card {
    width: 130px !important;
    height: 141px !important;
    margin-top: 40px !important;
}

.bp-body-card {
    min-height: 285px !important;
}

.bp-account-body-card {
    /* margin-top: 50px !important; */
    width: 85vw !important;
    margin-left: 48px !important;
    margin-bottom: 20px;
}

.bp-account-table-row {
    padding-left: 54px !important;
    margin-top: 35px !important;

}

.bp-payment-body-card {
    /* margin-top: 50px; */
    /* margin-top: 50px; */
    width: 85vw !important;
    margin-left: 48px !important;
}


.bp-sideBar {
    min-height: 100vh !important;
    width: 100px !important;
    background: #A502A5 0% 0% no-repeat padding-box;
    padding-top: 90px !important;
}



.bp-sidebarList {
    height: auto;
    width: 100%;
    padding: 0;
}

.bp-sidebarList .bp-rows {
    width: 100%;
    height: 50px;
    /* border: 1px solid black; */
    list-style-type: none;
    margin: 0%;
    display: flex;
    flex-direction: column;
    color: white;
    justify-content: center;
    align-items: center;
    font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
}


.bp-sidebar-icon {
    width: 25px !important;
    height: 25px !important;
}

#bp-side-title {
    margin-top: 5px;
}

.bp-side-main {
    margin-top: 3rem !important;
}

.bp-nav {
    display: flex !important;
    justify-content: space-between !important;
    align-items: center !important;
    margin-left: 49px !important;

}

.bp-client-portal-heading {
    font: normal normal 600 12px/10px Open Sans;
    /* letter-spacing: 0.48px; */
    color: #555555;
    text-transform: uppercase;
    opacity: 1;
}

.bp-rounded-circle {
    width: 40px;
    height: 43px;
    /* margin-left: -300px; */
}

.bp-login-rounded-circle {
    width: 70px;
    height: 73px;
    /* margin-left: -300px; */
}

.bp-header-icon {
    width: 34px !important;
    height: 34px !important;
}

.bp-header-name {
    /* font: normal normal normal 14px/28px Open Sans !important;
    letter-spacing: 0px;
    color: #333333 !important;
    opacity: 1; */

    letter-spacing: 0px;
    color: #333333 !important;
    opacity: 1;
    font-family: 'Open Sans';
    font-weight: 600 !important;
    color: #797777 !important;
    display: flex !important;
    align-items: center !important;
}

.bp-header-avatar {
    width: 50px;
    height: 50px;
    color: #797777;

}

.bp-header-routes {
    /* font-family: ''; */
    font-size: 17px;
    padding-top: 40px !important;
    font-family: 'Open Sans';
    font-weight: 600 !important;
}

.bp-header-route {
    font-size: 17px;
    font-family: 'Open Sans';
    font-weight: 600 !important;
}

.bp-header-route:hover,
.bp-header-route:active {
    color: #A502A5;
}

.bp-header-icon-name:hover {
    color: #A502A5 !important;
}

.bp-header-container {
    /* margin-left: 300px !important; */
}

.bp-sidebarHome {
    /* margin-top: 50px; */
    margin-top: 30px;
}

.bp-offcanvas-logo {
    padding-top: 13px;
}

.bp-offcanvas {
    width: 85% !important;
}

.offcanvas-backdrop {

    width: 100% !important;
    height: 100% !important;

}

.bp-verification-Auth-form {
    width: 630px;
    padding-top: 30px;
    padding-bottom: 120px;
    /* height: 583px !important; */
    background: #FFFFFF 0% 0% no-repeat padding-box !important;
    border: 1px solid #7070702B !important;
    border-radius: 6px !important;
    opacity: 1 !important;
}





/* .btn-close { */
/* position: absolute !important;
    left: 88vw !important;
    z-index: 999 !important;
    width: 10px !important;
    height: 10px !important;
    color: white !important;
    opacity: 1 !important;
    background-image: url(/src/assets/images/x-btn.png) !important;
    background-size: 25px !important;
    width: 20px !important;
    height: 20px !important; */
/* } */

.bp-header-close-bt {
    position: absolute !important;
    left: 88vw !important;
    z-index: 999 !important;
    width: 10px !important;
    height: 10px !important;
    color: white !important;
    opacity: 1 !important;
    background-size: 25px !important;
    width: 20px !important;
    height: 20px !important;
}


/* margin-left: 10vw; */

.bp-card-row-payment {
    margin-left: 2vw !important;
}

/* oct27 2002 END */

/* MEDIA QUERY */
@media screen and (max-width: 799px) {
    .bp-pad-modal-card {
        display: flex;
        justify-content: center !important;
    }

    .bp-pad-modal-card2 {
        display: flex;
        justify-content: center !important;
    }

    .bp-pad-modal-btn {

        display: flex !important;
        justify-content: center !important;
    }

    .bp-sideBar {
        display: none;
    }

    .bp-navbar-collapse {
        display: none !important;
    }

    .bp-nav {
        display: flex !important;
        justify-content: flex-end !important;
    }

    .bp-account-body-card {
        width: 96vw !important;
        margin-left: 0 !important;
    }

    .bp-payment-body-card {
        width: 96vw !important;
        margin-left: 0 !important;
        margin-top: 0 !important;
    }

    .bp-payment-add-card {
        min-width: 330px !important;
        margin-top: 15px !important;
    }

    .bp-appointment-font {
        padding-left: 44px !important;
        padding-bottom: 20px !important;
    }

    .bp-header-container {
        display: flex !important;
        justify-content: space-between !important;
        margin-left: 0 !important;
    }

    .bp-rounded-circle {
        margin-left: 0 !important;
    }

    .bp-payment-method-card {
        margin-right: 0 !important;
        margin-top: 15px !important;
    }

    .bp-header-logo-body {
        padding-top: 8px !important;
    }

    .bp-payment-name {
        margin-top: 10px !important;
    }

    .bp-common-names {
        margin-top: 0 !important;
    }

    .bp-payment-menu {
        /* transform: none !important */
        transform: translate3d(-20px, 33.6364px, 0px) !important;
    }

    .bp-account-table-row {
        padding-left: 41px !important;
        margin-top: 15px !important;
    }

    .bp-appointment-font2 {
        padding-left: 41px !important;
    }

    .bp-card-val {
        display: none !important;
    }

    .bp-card-year {
        padding-bottom: 14px;
        display: flex !important;
        justify-content: space-around !important;
    }

    .bp-tick-icon {
        display: -webkit-box !important;
        padding-left: 9px !important;
    }

    .bp-custom-select {
        width: 344px !important;
    }

    .bp-header-container {
        margin-left: 9px !important;
    }

    .bp-nav {
        margin-left: 0 !important;
    }

    .bp-card-row-payment {
        margin-left: -6px !important;
    }



    .bp-card-row-account {
        display: flex !important;
        justify-content: center !important;
    }

}

@media screen and (min-width: 799px) {
    .bp-header-icon-body {
        display: none !important;
    }

    .bp-card-val2 {
        display: none !important;
    }

}

.bp-card-val {
    padding-left: 10px !important;
    display: flex;
    align-items: center;
    margin-top: -6px;
}

.bp-card-val2 {
    margin-top: -34px !important;
    padding-left: 10px;


}

.bp-header-icon-body {
    margin-top: 18px !important;

}

@media (max-width:280px) and (max-height:653px) {
    .bp-payment-method-card {
        min-width: 0 !important;
    }

    .bp-payment-add-card {
        min-width: 255px !important;
    }

    /* CARD */
    .bp-card-image {
        padding-left: 25px !important;
        width: 100px !important;
    }

    .bp-payment-menu {
        transform: none !important
    }

    .bp-card-img {
        width: 50px;
        height: 50px;
    }

    .bp-card-number {
        margin-left: -8px !important;
    }

    .bp-tick-icon {
        margin-top: 8px !important;
    }
}

@media (max-width:1024px) and (max-height:600px) {





    .bp-appointment-font {
        padding-left: 279px !important;
        padding-bottom: 20px !important;
    }

    .bp-payment-method-card {
        margin-top: 15px !important;
    }


    .bp-payment-add-card {
        min-width: 330px !important;
        margin-top: 15px !important;
    }

    .bp-account-body-card {
        margin-left: 6px !important;
    }

    .bp-payment-body-card {
        margin-left: 6px !important;
    }

    .bp-sidebarHome {
        margin-top: 15px !important;
    }

    .bp-sideBar {
        height: 166vh !important;
    }

    .bp-payment-body-card {
        margin-top: -12px !important;
    }

    .bp-nav {
        margin-left: 8px !important;
    }

    .bp-appointment-font {
        padding-left: 282px !important;
    }

    .bp-card-row-payment {
        margin-left: 0 !important;
        display: flex !important;
        justify-content: center;
    }

    .bp-card-row-account {
        display: flex !important;
        justify-content: center;
    }
}

@media (max-width:820px) and (max-height:1180px) {

    .bp-pad-modal-card {
        display: flex;
        justify-content: center !important;
    }

    .bp-pad-modal-card2 {
        display: flex;
        justify-content: center !important;
    }

    .bp-payment-body-card {
        margin-left: 0 !important;
    }

    .bp-account-body-card {
        margin-left: 0 !important;
    }

    /* .bp-header-container {
        margin-left: 264px;
    } */

    .bp-payment-add-card {
        min-width: 330px !important;
    }

    .bp-nav {
        margin-left: 15px !important;
    }
}





/* MEDIA QUERY END */

.bp-payment-body-card-example {
    width: 85vw !important;
    /* margin-left: 48px !important; */
}

.bp-expired-card-bottom {
    margin-bottom: 30px !important;
}

.bp-save-btn {

    font-family: Playfair Display !important;
    padding-bottom: 55px;
    margin-top: 3px;
}

.bp-setup-payment-btn {

    width: 370px;
    /* 216px; */
    height: 46px;
    margin-top: 10px;


    display: flex;
    align-items: center;
    justify-content: center;

    /* UI Properties */
    background: #A501A5 0% 0% no-repeat padding-box;
    border: 2px solid #A501A5;
    border-radius: 6px;
    opacity: 1;

    font: normal normal 900 17px/47px Playfair Display;
    letter-spacing: 0.17px;
    color: #FFFFFF;

}

.bp-setup-payment-card {
    width: 18rem !important;
    min-height: 30vh !important;
    border: 2px solid #dfdada !important;

}

.bp-setup-payment-card:hover,
.bp-setup-payment-card:active,
.bp-setup-payment-card:focus {
    border-color: #A502A5 !important;
    box-shadow: #A502A5 !important;
}

.bp-setup-payment-card-true {
    width: 18rem !important;
    min-height: 30vh !important;
    border: 2px solid #A502A5 !important;
}

.bp-setup-payment-card-true:hover,
.bp-setup-payment-card-true:active,
.bp-setup-payment-card-true:focus {
    border-color: #A502A5 !important;
    box-shadow: #A502A5 !important;
}



.bp-setup-payment-card-icon {
    width: 100px !important;
    height: 100px !important;
}

.bp-pad-modal-card {
    display: flex;
    justify-content: flex-end;
}

.bp-padModal-btn {
    display: flex !important;
    justify-content: space-around !important;
    padding-top: 33px !important;
    /* margin-bottom: 20px !important; */
}

.bp-padCard-val {
    padding-left: 30px !important;
    display: flex !important;
    margin-top: 20px !important;
}

.bp-padModal-title {
    /* 25px */
    /* margin-top: 53px !important; */
    margin-top: 28px !important;
    /* padding-left: 40px !important; */
    font-family: 'Open Sans' !important;
    font-weight: 500;
    font-size: 16px;
    flex-direction: column;
    height: 243px;
}

.bp-modal-title2 {
    display: flex !important;
    justify-content: center !important;
    /* margin-top: 50px !important; */
}

.bp-padModal-Body {
    height: 313px !important;
}

.bp-padModal-btn2 {
    margin-bottom: 20px !important;
}

.bp-pad-charity-card {
    display: flex !important;
    justify-content: center !important;
    font-size: 16px !important;
}

.bp-pad-charity-card-french {
    display: flex !important;
    justify-content: center !important;
    font-size: 14px;
    text-align: center;
}

.bp-bp-bp {
    display: flex;
    justify-content: space-around;
}

.bp-fed-btn {
    width: 346px;
    height: 46px;
    border-radius: 6px;
    margin-top: 2px;
    border: none !important;
    font: normal normal 900 17px/47px Playfair Display;
    letter-spacing: 0.17px;
    box-shadow: 0px 2px 4px -2px;
}

.bp-fed-googleImg {
    width: 20px;
    height: 20px;
}

.bp-fed-span {
    padding-left: 10px;
}

.bp-modal-note {
    display: flex;
    justify-content: space-around;
    font-size: 12px;
    color: #5a5959;
    text-align: center;
}

.bp-defpymnt-btn {
    min-width: 100px !important;
    background-color: #A502A5 !important;
    border: 1px solid #A502A5 !important;
    border-radius: 4px !important;
    opacity: 1 !important;
}

.bp-defpymnt-title {
    font-weight: 500 !important;
    font-size: 18px !important;
}

.bp-defpymnt-content1 {
    width: 447px !important;
}

.bp-defpymnt-content2 {
    width: 474px !important;
    padding-top: 25px !important;
}

.bp-defpymnt-footer {
    border-top: none !important;
    display: flex !important;
    justify-content: flex-start !important;
}

.bp-bp-bp {
    display: flex;
    justify-content: space-around;
}

.bp-fed-btn {
    width: 346px;
    height: 46px;
    border-radius: 6px;
    margin-top: 2px;
    border: none !important;
    font: normal normal 900 17px/47px Playfair Display;
    letter-spacing: 0.17px;
    box-shadow: 0px 2px 4px -2px;
}
.bp-fed-googleImg{
    width: 20px;
    height: 20px;
}
.bp-fed-span{
    padding-left: 10px;
}
.btn-close:focus {
    box-shadow: none !important;
}

.bp-padPending-btn {
    width: 100% !important;
    background-color: #c2c2c2 !important;
    border: 1px solid #c2c2c2 !important;
    border-radius: 0px 0px 6px 6px;
    bottom: 0 !important;
    position: absolute;
}
.bp-pending-font {

    width: 182px;
    height: 17px;
    padding-left: 4.65px;
    /* UI Properties */
    text-align: left;
    font: normal normal normal 13px/28px Open Sans;
    letter-spacing: 0px;
    color: black !important;
    opacity: 1;
    cursor: pointer;
}

.Button--primary {
    background-color: #A501A5 !important;
    border-color: #A501A5 !important;
    color: #ffffff !important;
}

.Button--primary:not(:disabled):hover {
    background-color: #A501A5 !important;
}

.Button--primary:focus {
    box-shadow: 0 0 0 1px #A501A5 !important;
    outline: none;
}